import { Dispatch } from "react";
import { Portal } from "./router.interface";

export type ApplicationState = {
  portal: Portal;
  version: string;
};

export type ApplicationAction =
  | { type: "ChangeAppPortal"; portal: Portal }
  | { type: "RemoveAppPortal" };

export type ApplicationContextProps = {
  state: ApplicationState;
  isMobileLayout: boolean;
  dispatch: Dispatch<ApplicationAction>;
};

export interface FilterStateType {
  page?: number;
  size?: number;
  customerId?: string;
  name?: string;
  sort?: [string];
  "nameOrMobile.equals"?: string;
  "staffGroupId.equals"?: string | number;
  "name.contains"?: string;
  "customerNameOrMobile.contains?"?: string;
  "paymentStatus.equals"?: string;
  "isDisplay.equals"?: boolean;
  "packageCategory.id.equals"?: string;
  "productCategory.id.equals"?: string;
  "serviceCategory.id.equals"?: string;
}

export enum FilterKey {
  id = "id",
  page = "page",
  size = "size",
  customerNameOrMobile = "customerNameOrMobile.contains",
  serviceCategoryId = "serviceCategory.id.equals",
  serviceProductId = "productCategory.id.equals",
  packageCategoryId = "packageCategory.id.equals",
  staffGroupId = "staffGroupId.equals",
  paymentStatus = "paymentStatus.equals",
  customerId = "customerId.equals",
  nameContains = "name.contains",
  nameOrMobileEquals = "nameOrMobile.equals",
  name = "name",
  tab = "tab",
  displayStatusEquals = "isDisplay.equals",
}

export enum CustomerSource {
  Zalo = "Zalo",
  Facebook = "Facebook",
  Sale = "Sale",
}

export enum PaymentMethod {
  CASH = "CASH",
  CREDIT_CARD = "CREDIT_CARD",
  DEBIT_CARD = "DEBIT_CARD",
}

export enum Unit {
  Percent = "PERCENT",
  VND = "MONEY",
}

export enum PaymentStatusEnum {
  UNPAID = "UNPAID",
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
}

export enum STATUS_TYPE {
  Enable = "ENABLE",
  Disable = "DISABLE",
}

export enum StatusPaymentText {
  Warning = "Chưa thanh toán",
  Success = "Đã thanh toán",
}

export enum StatusPaymentColor {
  Warning = "warning",
  Success = "success",
}
