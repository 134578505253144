import { Dayjs } from "dayjs";

export interface AppointmentFormType {
  id: number;
  code: string;
  name: string;
  status: string;
  mobile: string;
  email: string;
  dob: string | Date;
  address: string;
  note: string;
  sex: string;
  avatarUrl: string;
  customerAccountId: number;
  loyaltyRefCustomerCount: number;
  currentLoyaltyPoint: number;
  isPatrons: boolean;
  isBlockShareImage: boolean;
  date: string | Date;
  hour: string;
}
export type StatusCustomer = "ENABLE" | "DISABLE";
export type DiscountUnit = "PERCENT" | "FIXED_AMOUNT";
export type ServiceType = "SERVICE" | "PRODUCT";
export type TypeStaff = "FULL_TIME" | "PART_TIME" | "CONTRACT";
export interface ServiceDetails {
  id: number;
  staffId: number;
  price: number;
  discountUnit: DiscountUnit;
  serviceType: ServiceType;
  discountValue: number;
  total: number;
  quantity: number;
  isPaid: boolean;
  staffRequestedByCustomer: boolean;
  percentCommission: number;
  commission: number;
  typeStaff: TypeStaff;
}

export interface AppointmentType {
  id: number;
  date: string;
  numberCustomer: number;
  bookingStatus: string;
  note: string;
  bookingCode: string;
  prepayStatus: string | null;
  customer: Customer;
  customerSource: CustomerSource | null;
}

export interface Customer {
  id: number;
  code: string;
  name: string;
  status: string;
  mobile: string;
  email: string | null;
  dob: string | null;
  address: string | null;
  note: string | null;
  sex: string;
  avatarUrl: string | null;
  customerAccountId: number | null;
  loyaltyRefCustomerCount: number | null;
  currentLoyaltyPoint: number | null;
  isPatrons: boolean | null;
  isBlockShareImage: boolean | null;
  group: Group;
  customerSource: CustomerSource;
  refByCustomer: string | null;
}

export interface Group {
  id: number;
  name: string;
  note: string | null;
  isDefault: boolean;
  discountUnit: string;
  discountValue: number;
  status: string;
  isFixed: boolean;
}

export interface CustomerSource {
  id: number;
  name: string;
  note: string | null;
  isDefault: boolean;
  status: string;
}

export interface ServiceDetailType {
  salonServiceId: number;
  staffId: number;
  note?: string;
}

export interface PayloadAppointmentType {
  date: string | Dayjs;
  note?: string;
  id: number;
  customerSourceId: number;
  details: ServiceDetailType[];
  customerId: number | string;
  mobile?: string;
  timeInHour?: string | Dayjs;
  status?: string;
}

export interface BookingDetails {
  id: number;
  note: string;
  staff: any | null;
  service: {
    id: number;
    name: string;
    description: string | null;
    code: string | null;
    price: number;
    timeInHour: number | null;
    notApplyLoyalty: boolean;
    maxCommission: number;
    maxCommissionUnit: "PERCENT" | "AMOUNT";
    maxCommissionOT: number | null;
    maxCommissionOTUnit: "PERCENT" | "AMOUNT" | null;
    allowBooking: boolean;
    allowUserApp: boolean;
    status: string;
  };
}

export interface CustomerSource {
  id: number;
  name: string;
  note: string | null;
  isDefault: boolean;
  status: string;
}

export interface Booking {
  id: number;
  date: string;
  numberCustomer: number;
  bookingStatus: "NEW" | "CONFIRMED" | "CANCELLED";
  note: string;
  bookingCode: string;
  prepayStatus: string | null;
  customer: Customer;
  customerSource: any | null;
  details: BookingDetails[];
}

export enum BookingStatusKey {
  NEW = "NEW",
  CONFIRMED = "CONFIRMED",
  CHECKIN = "CHECKIN",
  CHECKOUT = "CHECKOUT",
  CANCEL = "CANCEL",
  NOT_COMMING = "NOT_COMMING",
}

export enum BookingStatusText {
  NEW = "Mới",
  CONFIRMED = "Đã xác nhận",
  CHECKIN = "Checkin",
  CHECKOUT = "Checkout",
  CANCEL = "Đã hủy",
  NOT_COMMING = "Không đến",
}

export enum BookingStatusColor {
  NEW = "primary",
  CONFIRMED = "primary",
  CHECKIN = "primary",
  CHECKOUT = "success",
  CANCEL = "danger",
  NOT_COMMING = "warning",
}
